import { FC, useCallback } from "react";
import { useFormikContext, Form as FormikForm } from "formik";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useDrawer } from "auditaware-ui";
import { DrawerFormTypes } from "../../../constants/forms";
import { ESAValuationListInput } from "auditaware-types";
import GridTextField from "../GridTextField";

const FMVValuationFields: FC<{ index: number }> = ({ index }) => {
  return (
    <>
      <Grid size={12}>
        <GridTextField
          type="number"
          name={`valuations[${index}].fmvOriginalValue`}
          id={`fmvOriginalValue-${index}`}
          label="FMV Original Value"
        />
      </Grid>
      <Grid size={12}>
        <GridTextField
          type="number"
          name={`valuations[${index}].fmvAuditedValue`}
          id={`fmvAuditedValue-${index}`}
          label="FMV Audited Value"
        />
      </Grid>
    </>
  );
};
const EsaValuationFields: FC<{ index: number }> = ({ index }) => {
  return (
    <>
      <Grid size={12}>
        <GridTextField
          type="number"
          name={`valuations[${index}].advOriginalValue`}
          id={`advOriginalValue-${index}`}
          label="Ad Valorem Original Value"
        />
      </Grid>
      <Grid size={12}>
        <GridTextField
          type="number"
          name={`valuations[${index}].advAuditedValue`}
          id={`advAuditedValue-${index}`}
          label="Ad Valorem Audited Value"
        />
      </Grid>
    </>
  );
};

const EsaValuationForm: FC<DrawerFormTypes> = ({ drawerId }) => {
  const formik = useFormikContext();
  const { values } = useFormikContext<ESAValuationListInput>();
  const { toggleDrawer } = useDrawer();

  const handleCancelClick = useCallback(() => {
    toggleDrawer(drawerId);
  }, [drawerId, toggleDrawer]);

  return (
    <FormikForm noValidate>
      {values.valuations.map((valuation, index) => (
        <Grid
          container
          spacing={2}
          sx={{ mb: 2 }}
          direction="row"
          key={values.valuations[index].year}>
          <Grid size={12}>
            <Typography variant="h6" component="div">
              Year: {values.valuations[index].year}
            </Typography>
          </Grid>
          <Grid container size={{ xs: 12, md: 5 }} spacing={2} sx={{ mb: 6 }}>
            <EsaValuationFields key={valuation.id} index={index} />
          </Grid>
          <Grid container size={{ xs: 12, md: 5 }} spacing={2} sx={{ mb: 6 }}>
            <FMVValuationFields key={valuation.id} index={index} />
          </Grid>
        </Grid>
      ))}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "800px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={formik.isSubmitting}
          fullWidth={true}
        >
          Save
        </Button>
        <Button
          type="reset"
          variant="outlined"
          size="large"
          color="secondary"
          disabled={formik.isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
      </Box>
    </FormikForm>
  );
};

export default EsaValuationForm;
