import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { ESAValuation, ESAValuationListInput } from "auditaware-types";

import { GET_CASE } from "./cases";
import { GET_SUBJECT } from "./subjectHooks";

export type CreateEsaValuationResponse = {
  createEsaValuation: ESAValuation;
}

export type UpdateESAValuationResponse = {
  updateESAValuation: ESAValuation;
}

export const esaValuationFragment = gql`
  fragment EsaValuationFragment on EsaValuation {
    id
    caseId
    year
    advOriginalValue
    advAuditedValue
    fmvOriginalValue
    fmvAuditedValue
  }
`;

export const CREATE_ESA_VALUATION = gql`
  mutation CreateEsaValuation($caseId: ID!, $input: EsaValuationInput!) {
    createEsaValuation(caseId: $caseId, input: $input) {
      ...EsaValuationFragment
    }
  }
  ${esaValuationFragment}
`;

export const UPDATE_ESA_VALUATION = gql`
  mutation UpdateEsaValuation($id: ID!, $input: EsaValuationInput!) {
    updateEsaValuation(id: $id, input: $input) {
      ...EsaValuationFragment
    }
  }
  ${esaValuationFragment}
`;

export const useCreateESAValuation = (caseId: string) => {
  const [
    createEsaValuation,
    { error, data, ...rest },
  ] = useMutation<CreateEsaValuationResponse>(CREATE_ESA_VALUATION, {
    variables: { caseId },
    refetchQueries: [GET_CASE, GET_SUBJECT],
  });

  const updateFn = (input: ESAValuationListInput) => {
    return createEsaValuation({ variables: { input } });
  };

  const esaValuation = data?.createEsaValuation;
  const resultData = { error, esaValuation, ...rest };
  return [updateFn, resultData] as const;
};

export const useUpdateESAValuations = () => {
  const [
    updateESAValuation,
    { error, data, ...rest },
  ] = useMutation<UpdateESAValuationResponse>(UPDATE_ESA_VALUATION, {
    refetchQueries: [GET_CASE, GET_SUBJECT],
  });
  const [
    createEsaValuation,
  ] = useMutation<CreateEsaValuationResponse>(CREATE_ESA_VALUATION, {
    refetchQueries: [GET_CASE, GET_SUBJECT],
  });

  const updateFn = (esaValuations: ESAValuationListInput) => {
    // Filter valuations that have an ID for updates
    const updates = esaValuations.valuations
      .filter((esaValuation) => esaValuation.id != null)
      .map(({ caseId: _, ...esaValuation }) => esaValuation);

    // Filter valuations that do not have an ID for creation
    const creates = esaValuations.valuations
      .filter((esaValuation) => esaValuation.id == null)
      .map(({ id: _, ...esaValuation }) => esaValuation);

    // Create promises for updating existing valuations
    const updatePromises = updates.map(async (update) => {
      const { id, ...updateWithoutId } = update;
      await updateESAValuation({
        variables: {
          input: updateWithoutId,
          id: id,
        },
      });
    });

    // Create promises for creating new valuations
    const createPromises = creates.map(async (create) => {
      const { caseId, ...createWithoutCaseId } = create;
      await createEsaValuation({
        variables: {
          input: createWithoutCaseId,
          caseId: caseId,
        },
      });
    });

    // Return promises for both updates and creations
    return {
      updatePromises: Promise.all(updatePromises),
      createPromises: Promise.all(createPromises),
    };
  };

  const esaValuation = data?.updateESAValuation;
  const resultData = { error, esaValuation, ...rest };

  return [updateFn, resultData] as const;
};
