// apps/case-web/src/components/shared/Case/CaseFormFields.tsx

import React from "react";
import Grid from "@mui/material/Grid2";
import { SelectField } from "auditaware-ui";
import GridDateField from "../GridDateField";
import CaseTypeSelect from "./CaseTypeSelect";
import AssigneeSelect from "./AssigneeSelect";
import { CaseStatus, caseStatusNames } from "auditaware-types";

const statusOptions = Object.values(CaseStatus).map((value) => ({
  display: caseStatusNames[value],
  value,
}));

const caseCycleOptions = [
  { display: "Annual", value: "Annual" },
  { display: "Quarterly - Q1", value: "Q1" },
  { display: "Quarterly - Q2", value: "Q2" },
  { display: "Quarterly - Q3", value: "Q3" },
  { display: "Quarterly - Q4", value: "Q4" },
];

interface CaseFormFieldsProps {
  isEditMode: boolean;
  objectNamePrefix?: string;
}

const addPrefix = (prefix: string | undefined, fieldName: string): string => {
  return `${prefix ? `${prefix}.` : ""}${fieldName}`;
};

const CaseFormFields: React.FC<CaseFormFieldsProps>
= ({ isEditMode, objectNamePrefix }) => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 6 }}>
        <SelectField
          name={addPrefix(objectNamePrefix, "status")}
          label="Status"
          options={statusOptions}
          disabled={!isEditMode}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <CaseTypeSelect
          name={addPrefix(objectNamePrefix, "caseType")}
          label="Case Type"
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <SelectField
          options={caseCycleOptions}
          label="Case Cycle"
          name={addPrefix(objectNamePrefix, "cycle")}
        />
      </Grid>
      <GridDateField
        name={addPrefix(objectNamePrefix, "scheduledOn")}
        label="Case Schedule Date"
        xs={12}
        md={6}
      />
      <GridDateField
        name={addPrefix(objectNamePrefix, "confirmationSentOn")}
        label="Confirmation Sent Date"
        xs={12}
        md={6}
      />
      <GridDateField
        name={addPrefix(objectNamePrefix, "documentsReceivedOn")}
        label="Documents Received Date"
        xs={12}
        md={12}
      />
      <Grid size={{ xs: 12, md: 12 }}>
        <AssigneeSelect
          name={addPrefix(objectNamePrefix, "assigneeUid")}
          label="assignee"
        />
      </Grid>
    </Grid>
  );
};

export default CaseFormFields;
