import { FC, useCallback } from "react";
import { Formik } from "formik";
import EsaValuationForm from "./EsaValuationForm";
import {
  ESAValuation,
  ESAValuationListInput,
  ESAValuationListSchema as validationSchema,
} from "auditaware-types";
import { useSnackbarContext } from "../../../hooks/useSnackbarContext";
import { useDrawer } from "auditaware-ui";
import {
  useUpdateESAValuations,
} from "../../../hooks/esaValuationHooks";
import Box from "@mui/system/Box";

type EditEsaValuationProps = {
  valuations: ESAValuation[];
  drawerId: string;
};

const EditEsaValuation: FC<EditEsaValuationProps> = ({ valuations, drawerId }) => {
  const [updateESAValuations] = useUpdateESAValuations();
  const { toggleDrawer } = useDrawer();
  const { setSuccess, setError } = useSnackbarContext();

  const handleSubmit = useCallback(
    async (values: ESAValuationListInput) => {
      try {
        await updateESAValuations(values);
        setSuccess("ESA Valuation updated");
        toggleDrawer(drawerId);
      } catch (err) {
        console.error(err);
        setError("Error updating valuation");
      }
    },
    [updateESAValuations, setError, setSuccess, toggleDrawer, drawerId]
  );

  return (
    <Box component="div" className="test">
      <Formik<ESAValuationListInput>
        initialValues={{
          valuations: valuations.map((valuation) => ({
            ...valuation,
            id: valuation.id ?? undefined,
          })),
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <EsaValuationForm drawerId={drawerId} />
      </Formik>
    </Box>
  );
};

export default EditEsaValuation;
