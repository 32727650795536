import { FC, useMemo } from "react";
import { SelectField, SelectFieldProps } from "auditaware-ui";
import useAssignees from "../../../hooks/useAssignees";

export const UserSelect: FC<SelectFieldProps> = ({
  name,
  label,
  required = false,
  id,
  autoComplete,
}) => {
  const assignees = useAssignees();

  const options = useMemo(
    () => [
      { value: "", display: "Unassigned" },
      ...assignees.map(({ id: value, displayName: display }) => ({ value, display })),
    ],
    [assignees]
  );

  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} />;
};

const AssigneeSelect: FC<SelectFieldProps> = (props) => (
  <UserSelect
    {...props}
    name={props.name ? props.name : "assigneeUid"}
    label={props.label ? props.label : "Assignee"} />
);

export default AssigneeSelect;
