import { useMemo } from "react";
import { getGridDateOperators, GridColDef } from "@mui/x-data-grid-pro";

import { options as caseTypeOptions } from "../components/shared/Form/CaseTypeSelect";
import {
  nestedValueGetter,
  renderCell,
  renderDateCell,
  renderHeader,
  renderNoHeader,
  renderLinkCell,
} from "../components/shared/DataGridHelpers";

import { CaseAvatar } from "../components/shared/AllListGrid/TypeAvatar";
import GridItem from "../components/shared/AllListGrid/GridItem";
import { PrettyStatus } from "../components/shared/PrettyStatus";
import useAssignees from "./useAssignees";

const useCaseColumns = () => {
  const assignees = useAssignees();

  const assigneeOptions = useMemo(
    () => [
      { value: "unassigned", label: "Unassigned" },
      ...assignees.map(({ id: value, displayName: label }) => ({ value, label })),
    ],
    [assignees]
  );

  const caseColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "caseType",
        type: "singleSelect",
        valueOptions: caseTypeOptions,
        headerName: "Type",
        renderHeader,
        width: 40,
        renderCell: ({ row }) => <CaseAvatar caseType={row.caseType} />,
        display: "flex",
      },
      {
        field: "status",
        headerName: "Status",
        renderHeader,
        flex: 1,
        display: "flex",
        renderCell: (params) => (
          <GridItem title={params.row.status} variant="body2" sx={{ fontWeight: 900 }}>
            <PrettyStatus status={params.row.status} />
          </GridItem>
        ),
      },
      {
        field: "cycle",
        headerName: "Cycle",
        renderHeader,
        flex: 1,
        display: "flex",
        renderCell,
      },
      {
        field: "location.parcelId",
        headerName: "Parcel ID",
        renderHeader,
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "subject.externalId",
        headerName: "Unique ID",
        renderHeader,
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "subject.contact.primaryName",
        headerName: "Subject",
        flex: 1,
        display: "flex",
        renderHeader,
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "location.countyName",
        headerName: "County",
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
      },
      {
        field: "location.jurisdictionName",
        headerName: "Jurisdiction",
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
      },
      {
        type: "singleSelect",
        field: "assignee.displayName",
        valueOptions: assigneeOptions,
        headerName: "Assignee",
        renderHeader,
        renderCell: ({ row }) => row.assignee?.displayName,
        valueGetter: (value, row) => row.assignee?.id || "unassigned",
        flex: 1,
        display: "flex",
      },
      {
        field: "scheduledOn",
        headerName: "Scheduled Date",
        renderHeader,
        renderCell: renderDateCell,
        flex: 1,
        display: "flex",
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "confirmationSentOn",
        headerName: "Confirmation Sent On",
        renderHeader,
        renderCell: renderDateCell,
        flex: 1,
        display: "flex",
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "documentsReceivedOn",
        headerName: "Docs Received On",
        renderHeader,
        renderCell: renderDateCell,
        flex: 1,
        display: "flex",
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "primaryContact.displayName",
        headerName: "Primary Name",
        renderHeader,
        renderCell: (params) => {
          const { primaryContact } = params.row;
          const displayName
            = [primaryContact?.primaryName, primaryContact?.secondaryName]
              .join(" ")
              .trim() || " ";

          return displayName;
        },
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "primaryContact.phoneNumber",
        headerName: "Primary Phone",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "primaryContact.email",
        headerName: "Primary Email",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "location.address",
        headerName: "Location Address",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "location.city",
        headerName: "Location City",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "location.stateAbbr",
        headerName: "Location State",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "location.zipCode",
        headerName: "Location Zip",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "details",
        headerName: "Details",
        renderHeader: renderNoHeader,
        sortable: false,
        disableColumnMenu: true,
        width: 40,
        renderCell: renderLinkCell,
        valueGetter: (value, row) =>
          ["/details", row.subject.id, row.id].filter((x) => x).join("/"),
        display: "flex",
      },
    ],
    [assigneeOptions]
  );

  return caseColumns;
};

export default useCaseColumns;
