import { FC } from "react";
import { Box, List, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Attachment } from "auditaware-types";

import AttachmentItem from "./AttachmentItem";
import Upload from "./Upload";

interface CaseAttachmentProps {
  caseId: string;
  attachments: Attachment[];
}

const CaseAttachments: FC<CaseAttachmentProps> = ({
  caseId,
  attachments,
}) => {
  const StyledList = styled(List)({
    "li:last-child": {
      borderBottom: "none",
    },
  });

  const overviewBgColor = {
    borderRadius: 1,
    // my: 1,
    bgcolor: (theme: any) => ("light" === theme.palette.mode ? grey[100] : grey[800]),
    px: 2,
  };

  const sortedAttachments = attachments.slice().sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <Grid size={{ xs: 12, md: 12, lg: 12 }}>
      <Paper>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Box>
            <Typography component="h1" variant="h5" padding="16px">
            Attachments
            </Typography>
          </Box>
          <Box component="span" mr={1}>
          </Box>
        </Box>
        <Box
          sx={overviewBgColor}
          marginBottom={"15px"}
          paddingRight={"16px"}
          paddingBottom={"8px"}
          paddingTop={"8px"}
        >
          <Upload caseId={caseId} />
          {sortedAttachments.length ? (
            <StyledList sx={{ py: 0 }}>
              {sortedAttachments.map((attachment) => (
                <AttachmentItem key={attachment.id} attachment={attachment} />
              ))}
            </StyledList>
          ) : (
            <p>No attachments available</p>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default CaseAttachments;
