import { TextFieldProps } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TextField } from "auditaware-ui";

export type GridTextFieldProps = {
    name: string;
    label?: string;
    disabled?: boolean;
    type?: string;
    InputLabelProps?: any;
    InputProps?: TextFieldProps
    id?: string;
    xs?: number;
    md?: number;
    lg?: number;
};

const defaultFieldLabel = (name: string) =>
  (name.split(".").pop() as string)
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/^./, (str) => str.toUpperCase());

const GridTextField = ({
  name,
  label = defaultFieldLabel(name),
  id = name.split(".").pop() as string,
  xs,
  md,
  lg,
  ...rest
}: GridTextFieldProps) => (
  <Grid size={12}>
    <TextField id={name} name={name} label={label} {...rest} fullWidth />
  </Grid>
);

export default GridTextField;
