import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import { DrawerPortalProvider, DrawerProvider, useAuditAwareTheme } from "auditaware-ui";
import { ApolloProvider } from "auditaware-auth";

import AppRoutes from "./components/shared/AppRoutes";
import useConfig from "./hooks/useConfig";
import SnackBarProvider from "./components/shared/SnackBar/SnackBarProvider";

function App() {
  const { firebaseConfig, useFirebaseEmulator, authCookieName, auditAwareApi }
    = useConfig();

  const theme = useAuditAwareTheme();

  return (
    <SUBContextProvider
      config={firebaseConfig}
      authCookieName={authCookieName}
      emulate={useFirebaseEmulator}
    >
      <ApolloProvider uri={auditAwareApi}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <SnackBarProvider>
              <CssBaseline />
              {/* DrawerPortalProvider is for the new style drawer */}
              <DrawerPortalProvider>
                {/* DrawerProvider is for the legacy drawer */}
                <DrawerProvider>
                  <AppRoutes />
                </DrawerProvider>
              </DrawerPortalProvider>
            </SnackBarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </SUBContextProvider>
  );
}

export default App;
