import { FC, useMemo } from "react";
import { Paper, Grid, Button } from "@mui/material";
import { ModeEdit } from "@mui/icons-material";

import { Subject, Location, OrganizationContact, ResidenceData } from "auditaware-types";
import { CustomDrawer, useDrawer } from "auditaware-ui";

import { List, Item } from "../List";
import { SubjectAvatar } from "../AllListGrid/TypeAvatar";
import OverviewBox from "../OverviewBox";
import BoxHeader from "../BoxHeader";
import BoxTop from "../BoxTop";
import StyledLink from "../Styled/StyledLink";
import SubjectTypeSwitcher from "../SubjectTypeSwitcher";
import OrganizationView from "../Subject/OrganizationView";
import ResidenceView from "../Subject/ResidenceView";
import EditSubject from "../Subject/EditSubject";

type OrganizationSubject = {
  location?: Location;
  contact?: OrganizationContact;
};

const padProtocol = (url: string | undefined) => {
  if (!url) return "";
  return url.startsWith("http") ? url : `https://${url}`;
};

type SubjectProps = {
  subject: Subject;
};

const SubjectDetails: FC<SubjectProps> = ({ subject }) => {
  const { location, contact }: OrganizationSubject = subject || {};

  const switcherMap = useMemo(
    () => ({
      Organization: (
        <OrganizationView
          firstAuditYear={subject?.firstAuditYear}
          naics={contact?.naics}
        />
      ),
      Residence: <ResidenceView residenceData={subject?.subjectData as ResidenceData} />,
      Individual: <></>,
    }),
    [subject, contact]
  );

  const { toggleDrawer } = useDrawer();

  const objectsUnavailable = !subject || !location || !contact;
  if (objectsUnavailable) return null;

  return (
    <Grid item xs md={12} lg={12} sx={{ marginBottom: "15px" }}>
      <Paper>
        <BoxTop>
          <BoxHeader>
            <SubjectAvatar subjectType={subject.subjectType} />
            &nbsp;
            <span style={{ fontWeight: 700 }}>{contact.primaryName}</span>
          </BoxHeader>
          <div>
            <Button
              type="submit"
              variant="text"
              color="primary"
              aria-label="Edit Subject"
              title="Edit Subject"
              startIcon={<ModeEdit />}
              sx={{ borderRadius: 24 }}
              onClick={() => toggleDrawer("editSubject")}
            >
              Edit
            </Button>
            <CustomDrawer
              title="Edit Subject"
              drawerId="editSubject"
              content={<EditSubject />}
            />
          </div>
        </BoxTop>
        <OverviewBox>
          <Grid container spacing={2}>
            <Grid item md lg={3}>
              <List>
                <Item primary="Address" secondary={location.address} />
                <Item primary="City" secondary={location.city} />
                <Item primary="State" secondary={location.stateAbbr} />
                <Item primary="Zip" secondary={location.zipCode} />
              </List>
            </Grid>
            <Grid item md lg={3}>
              <List>
                <Item primary="Parcel ID" secondary={location.parcelId} />
                <Item primary="Unique ID" secondary={subject.externalId} />
                <Item primary="Phone Number" secondary={contact.phoneNumber} />
                <Item primary="Secondary Name" secondary={contact.secondaryName} />
              </List>
            </Grid>
            <Grid item md lg={3}>
              <List>
                <Item
                  primary="Email"
                  secondary={
                    <StyledLink href={`mailto:${contact.email}`}>
                      {contact.email}
                    </StyledLink>
                  }
                />
                <Item
                  primary="Website"
                  secondary={
                    <StyledLink href={padProtocol(contact.website)} target="_blank">
                      {contact.website}
                    </StyledLink>
                  }
                />
                <Item primary="Jurisdiction" secondary={location.jurisdictionName} />
                <Item primary="County" secondary={location.countyName} />
              </List>
            </Grid>
            <Grid item md lg={3}>
              <List>
                <SubjectTypeSwitcher
                  subjectType={subject.subjectType}
                  switcherMap={switcherMap}
                />
                <Item
                  primary="Additional Information"
                  secondary={subject?.additionalInformation}
                />
              </List>
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>

        </OverviewBox>
      </Paper>
    </Grid>
  );
};

export default SubjectDetails;
