import { Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import GridTextField from "../../shared/GridTextField";
import SearchIcon from "@mui/icons-material/Search";

type ContactFieldsProps = {
  prefix: string;
  title: string;
};

const ContactsSearch = ({ prefix, title }: ContactFieldsProps) => {
  return (
    <Box
      p={5}
      textAlign="center"
      sx={{
        backgroundColor: "#121212",
      }}
    >
      <Typography variant="h6" mb={2}>
        {title} Search
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid size={{ xs: 12, md: 4 }}>
          <GridTextField name={`${prefix}.primaryName`} label="First Name" />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <GridTextField name={`${prefix}.secondaryName`} label="Last Name" />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <GridTextField name={`${prefix}.address`} />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" mt={1}>
        <Grid size={{ xs: 12, md: 4 }}>
          <GridTextField name={`${prefix}.phoneNumber`} />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <GridTextField name={`${prefix}.email`} />
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            fullWidth
            sx={{ height: "56px" }}
          ></Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactsSearch;
