import React, { FC } from "react";
import { Grid, Paper, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { EditNote, EditLocationAlt } from "@mui/icons-material";

import { Case, CaseType, Contact } from "auditaware-types";
import { Drawer, useDrawerControls } from "auditaware-ui";

import EditCase from "./EditCase";
import EditContacts from "./EditContacts";
import { List, Item } from "../List";
import { CaseIcon } from "../AllListGrid/TypeAvatar";
import { PrettyStatus } from "../PrettyStatus";
import OverviewBox from "../OverviewBox";
import BoxHeader from "../BoxHeader";
import BoxTop from "../BoxTop";
import StyledLink from "../Styled/StyledLink";
import useFormattedDate from "../../../hooks/useFormattedDate";
import { caseColorFromTheme } from "../../../lib/appTypeColors";

interface CaseOverviewProps {
  caseData: Case;
}

const CaseTypeBox = styled("span", { shouldForwardProp: (prop) => prop === "children" })<{
  caseType: CaseType | null;
}>(({ theme, caseType }) => ({
  display: "flex",
  alignItems: "center",
  color: caseColorFromTheme(theme, caseType),
}));

const ContactInfo: FC<{ contact: Contact }> = ({ contact }) => {
  const name = [contact.primaryName, contact.secondaryName].filter(Boolean).join(" ");

  return (
    <Grid item md lg={6}>
      <List>
        <Item primary="Name" secondary={name.trim()} />
        <Item
          primary="Email"
          secondary={
            contact.email && (
              <StyledLink href={`mailto:${contact.email}`}>{contact.email}</StyledLink>
            )
          }
        />
        <Item primary="Phone" secondary={contact.phoneNumber} />
        <Item primary="Address" secondary={contact.address} />
        <Item primary="City" secondary={contact.city} />
        <Item primary="State" secondary={contact.stateAbbr} />
        <Item primary="Zip" secondary={contact.zipCode} />
      </List>
    </Grid>
  );
};

type EditCaseButtonProps = {
  onClick: () => void;
};

const EditCaseButton: FC<EditCaseButtonProps> = ({ onClick }) => (
  <Button
    type="submit"
    variant="text"
    color="primary"
    aria-label="Edit Case"
    title="Edit Contacts"
    sx={{ borderRadius: 24 }}
    startIcon={<EditNote />}
    onClick={onClick}
  >
    Edit
  </Button>
);

const CaseOverview: FC<CaseOverviewProps> = ({ caseData }) => {
  const scheduledOnDate = useFormattedDate(caseData.scheduledOn);
  const confirmationSentOnDate = useFormattedDate(caseData.confirmationSentOn);
  const documentsReceivedOnDate = useFormattedDate(caseData.documentsReceivedOn);

  const [caseDrawerProps, openCaseDrawer, closeCaseDrawer] = useDrawerControls({
    title: "Edit Case",
  });

  const [contactDrawerProps, openContactDrawer, closeContactDrawer] = useDrawerControls({
    title: "Edit Contacts",
  });

  if (!caseData) return null;

  const { subject, assignee } = caseData;
  const safeAssigneeName = assignee?.displayName || assignee?.email;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs md={6} lg={6}>
          <Paper>
            <BoxTop>
              <BoxHeader>Case</BoxHeader>
              <div>
                <EditCaseButton onClick={openCaseDrawer} />
                <Drawer {...caseDrawerProps}>
                  <EditCase
                    caseData={caseData}
                    subject={subject}
                    closeDrawer={closeCaseDrawer}
                  />
                </Drawer>
              </div>
            </BoxTop>
            <OverviewBox>
              <Grid container spacing={2}>
                <Grid item md lg={6}>
                  <List>
                    <Item primary="Case Type">
                      <CaseTypeBox caseType={caseData.caseType}>
                        <CaseIcon caseType={caseData.caseType} />
                        &nbsp;
                        {caseData.caseType}
                      </CaseTypeBox>
                    </Item>
                    <Item primary="Status">
                      <PrettyStatus status={caseData.status} />
                    </Item>
                    <Item
                      primary="Assignee"
                      secondary={safeAssigneeName || <i>Unassigned</i>}
                    />
                    <Item primary="Cycle" secondary={caseData.cycle} />
                    <Item primary="Scheduled On" secondary={scheduledOnDate} />
                    <Item
                      primary="Confirmation Sent"
                      secondary={confirmationSentOnDate}
                    />
                    <Item primary="Docs Received" secondary={documentsReceivedOnDate} />
                  </List>
                </Grid>
                <Grid item md lg={6}>
                  <List>
                    <Item primary="Parcel ID" secondary={caseData.location?.parcelId} />
                    <Item primary="Address" secondary={caseData.location?.address} />
                    <Item primary="City" secondary={caseData.location?.city} />
                    <Item primary="State" secondary={caseData.location?.stateAbbr} />
                    <Item primary="Zip" secondary={caseData.location?.zipCode} />
                    <Item
                      primary="County Name"
                      secondary={caseData.location?.countyName}
                    />
                    <Item
                      primary="Jurisdiction Name"
                      secondary={caseData.location?.jurisdictionName}
                    />
                  </List>
                </Grid>
              </Grid>
            </OverviewBox>
          </Paper>
        </Grid>

        <Grid item xs md={6} lg={6}>
          <Paper>
            <BoxTop>
              <BoxHeader>Contacts</BoxHeader>
              <div>
                <Button
                  type="submit"
                  variant="text"
                  color="primary"
                  aria-label="Edit Contacts"
                  title="Edit Contacts"
                  sx={{ borderRadius: 24 }}
                  startIcon={<EditLocationAlt />}
                  onClick={openContactDrawer}
                >
                  Edit
                </Button>
                <Drawer {...contactDrawerProps}>
                  <EditContacts
                    caseData={caseData}
                    subject={subject}
                    closeDrawer={closeContactDrawer}
                  />
                </Drawer>
              </div>
            </BoxTop>
            <OverviewBox>
              <Grid container spacing={2}>
                <ContactInfo contact={caseData.primaryContact} />
                <ContactInfo contact={caseData.secondaryContact} />
              </Grid>
            </OverviewBox>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CaseOverview;
