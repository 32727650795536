import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import { FC, useCallback } from "react";
import { useDrawer } from "auditaware-ui";
import { User } from "firebase/auth";

import { useUserDoc } from "@reasongcp/react-fire-sub";
import { Note, NoteSchema as validationSchema } from "auditaware-types";

import Form from "./NoteForm";
import { useSnackbarContext } from "../../../hooks/useSnackbarContext";
import { useUpdateNote } from "../../../hooks/noteHooks";
import useCoercedDateScalar from "../../../hooks/useCoercedDateScalar";
import InitialsAvatar from "../../shared/InitialsAvatar";

export interface EditNoteProps {
  note: Note;
}

const EditNote: FC<EditNoteProps> = ({ note }) => {
  const user = useUserDoc() as User | null;
  const { setSuccess, setError } = useSnackbarContext();
  const { toggleDrawer } = useDrawer();

  const [updateNote] = useUpdateNote(note.id);

  const handleSubmit = useCallback(
    async (values: Note) => {
      try {
        await updateNote(values);
        setSuccess("Note saved successfully");
        toggleDrawer(`editNote-${note.id}`);
      } catch (err) {
        console.error(err);
        setError("Subject failed to save");
      }
    },
    [updateNote, setError, setSuccess, toggleDrawer, note.id]
  );

  const safeUpdatedAt = useCoercedDateScalar(note.updatedAt);

  if (!user) return null;

  const authorName = note.author.displayName || note.author.email;

  return (
    <Grid size={12}>
      <Box>
        <Box
          mb={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <InitialsAvatar user={note.author} />
            <Typography variant="h6" sx={{ ml: 1 }}>
              {authorName}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "end" }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              <small>{safeUpdatedAt.toLocaleString()}</small>
            </Typography>
          </Box>
        </Box>

        <Formik
          initialValues={note}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form drawerId={`editNote-${note.id}`} />
        </Formik>
      </Box>
    </Grid>
  );
};

export default EditNote;
