// apps/case-web/src/hooks/useUploadHooks.ts
import { gql, useMutation } from "@apollo/client";
import { GET_CASE } from "./cases";
import { useOrganization } from "auditaware-auth";
import { AttachmentInput } from "auditaware-types";
// Define the GraphQL mutations
export const CREATE_UPLOAD_PARAMS = gql`
  mutation CreateUploadParams($caseId: ID!, $input: AttachmentInput!) {
    createAttachment(caseId: $caseId, input: $input) {
      id
      url
    }
  }
`;

export const FINALIZE_UPLOAD = gql`
  mutation FinalizeUpload($attachmentId: ID!) {
    finalizeAttachment(attachmentId: $attachmentId) {
      id
      url
    }
  }
`;

// Custom hook for createUploadParams mutation
export const useCreateUploadParams = (caseId: string) => {
  const [createUrlParams, { error, data, ...rest }] = useMutation(CREATE_UPLOAD_PARAMS);

  const updateFn = (input: AttachmentInput) => {
    return createUrlParams({ variables: { caseId, input } });
  };

  if (error) {
    console.error(error);
  }

  const resultData = { error, data, ...rest };
  return [updateFn, resultData] as const;
};

// Custom hook for finalizeUpload mutation
export const useFinalizeUpload = (caseId: string) => {
  const org = useOrganization();
  const [finalizeUpload, { error, data, ...rest }] = useMutation(FINALIZE_UPLOAD, {
    variables: { caseId },
    refetchQueries: [
      { query: GET_CASE, variables: { id: caseId, orgId: org.id } },
    ],
  });

  if (error) {
    console.error(error);
  }

  const updateFn = (attachmentId: string) => {
    return finalizeUpload({ variables: { attachmentId } });
  };


  const resultData = { error, data, ...rest };
  return [updateFn, resultData] as const;
};
