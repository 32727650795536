import React, { FC } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { EditNote } from "@mui/icons-material";

import { Drawer, useDrawerControls } from "auditaware-ui";
import { Case } from "auditaware-types";

import useFormattedDate from "../../../hooks/useFormattedDate";
import BoxTop from "../BoxTop";
import BoxHeader from "../BoxHeader";
import OverviewBox from "../OverviewBox";
import { Item, List } from "../List";
import EditResultsSummary from "./EditResults";

type CaseResultsProps = {
  caseData: Case;
};

const CaseResults: FC<CaseResultsProps> = ({ caseData }) => {
  const resultsLetterSentOnDate = useFormattedDate(caseData.resultsLetterSentOn);
  const reviewCompletedOnDate = useFormattedDate(caseData.reviewCompletedOn);

  const [resultsDrawerProps, openResultsDrawer, closeResultsDrawer] = useDrawerControls({
    title: "Edit Results Summary",
  });

  const safeReviewedByName
    = caseData.reviewedBy?.displayName || caseData.reviewedBy?.email;

  return (
    <Paper>
      <BoxTop>
        <BoxHeader>Result Summary</BoxHeader>
        <Button
          variant="text"
          color="primary"
          startIcon={<EditNote />}
          onClick={openResultsDrawer}
        >
          Edit
        </Button>
        <Drawer {...resultsDrawerProps}>
          <EditResultsSummary
            caseData={caseData}
            closeDrawer={closeResultsDrawer} />
        </Drawer>
      </BoxTop>
      <OverviewBox>
        <Grid container spacing={2}>
          <Grid item md lg={6}>
            <List>
              <Item
                primary="Result"
                secondary={caseData.result?.name}
              />
              <Item
                primary="Result Summary"
                secondary={caseData.resultSummary}
              />
              <Item
                primary="Reviewed By"
                secondary={safeReviewedByName}
              />
            </List>
          </Grid>
          <Grid item md lg={6}>
            <List>
              <Item
                primary="Result Letter Sent"
                secondary={resultsLetterSentOnDate} />
              <Item
                primary="Reviewed Completed"
                secondary={reviewCompletedOnDate} />
            </List>
          </Grid>
        </Grid>
      </OverviewBox>
    </Paper>
  );
};

export default CaseResults;
