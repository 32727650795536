import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

export const exportColumnVisibilityModel: GridColumnVisibilityModel = {};
export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  "subject.subjectData.score": false,
  "subject.contact.naics": false,
  "subject.location.countyName": false,
  "subject.location.jurisdictionName": false,
  "subject.location.address": false,
  "subject.location.city": false,
  "subject.location.stateAbbr": false,
  "subject.location.zipCode": false,
  "subject.contact.website": false,
  "subject.contact.phoneNumber": false,
  "subject.contact.email": false,
  "case.cycle": false,
  "case.location.parcelId": false,
  "case.location.countyName": false,
  "case.location.jurisdictionName": false,
  "case.confirmationSentOn": false,
  "case.documentsReceivedOn": false,
  "case.location.address": false,
  "case.location.city": false,
  "case.location.stateAbbr": false,
  "case.location.zipCode": false,
  mostRecent: false,
  active: false,
};

export const splitViewColumnVisibilityModel: GridColumnVisibilityModel = {
  ...defaultColumnVisibilityModel,
  "subject.location.parcelId": false,
  "subject.externalId": false,
  "case.primaryContact.displayName": false,
  "case.primaryContact.phoneNumber": false,
  "case.primaryContact.email": false,
};

export const businessInsightColumnVisibilityModel: GridColumnVisibilityModel = {
  "subject.subjectData.score": false,
  "subject.location.countyName": false,
  "subject.location.jurisdictionName": false,
  "subject.location.address": false,
  "subject.location.city": false,
  "subject.location.stateAbbr": false,
  "subject.location.zipCode": false,
  "subject.contact.phoneNumber": false,
  "case.cycle": false,
  "case.location.parcelId": false,
  "case.location.countyName": false,
  "case.location.jurisdictionName": false,
  "case.confirmationSentOn": false,
  "case.documentsReceivedOn": false,
  "case.location.address": false,
  "case.location.city": false,
  "case.location.stateAbbr": false,
  "case.location.zipCode": false,
  mostRecent: false,
  active: false,
};

export const residenceInsightColumnVisibilityModel: GridColumnVisibilityModel = {
  "subject.contact.naics": false,
  "subject.contact.website": false,
  "subject.location.countyName": false,
  "subject.location.jurisdictionName": false,
  "subject.location.address": false,
  "subject.location.city": false,
  "subject.location.stateAbbr": false,
  "subject.location.zipCode": false,
  "subject.contact.phoneNumber": false,
  "subject.contact.email": false,
  "case.cycle": false,
  "case.location.parcelId": false,
  "case.location.countyName": false,
  "case.location.jurisdictionName": false,
  "case.confirmationSentOn": false,
  "case.documentsReceivedOn": false,
  "case.location.address": false,
  "case.location.city": false,
  "case.location.stateAbbr": false,
  "case.location.zipCode": false,
  mostRecent: false,
  active: false,
};

export const caseVisibilityModel: GridColumnVisibilityModel = {
  "location.countyName": false,
  "location.parcelId": false,
  "subject.externalId": false,
  "location.jurisdictionName": false,
  "location.address": false,
  "location.city": false,
  "location.stateAbbr": false,
  "location.zipCode": false,
  confirmationSentOn: false,
  documentsReceivedOn: false,
  mostRecent: false,
  active: false,
};

// "subject.subjectType": false,
// "case.caseType": false,
// "subject.location.parcelId": false,
// "subject.externalId": false,
// "subject.contact.primaryName": false,
// "subject.contact.naics": false,
// "subject.location.countyName": false,
// "subject.location.jurisdictionName": false,
// "subject.location.address": false,
// "subject.location.city": false,
// "subject.location.stateAbbr": false,
// "subject.location.zipCode": false,
// "subject.contact.website": false,
// "subject.contact.phoneNumber": false,
// status: false,
// "case.cycle": false,
// "case.location.parcelId": false,
// "case.location.countyName": false,
// "case.location.jurisdictionName": false,
// "case.assignee.displayName": false,
// "case.scheduledOn": false,
// "case.confirmationSentOn": false,
// "case.documentsReceivedOn": false,
// "case.primaryContact.displayName": false,
// "case.primaryContact.phoneNumber": false,
// "case.primaryContact.email": false,
// "case.location.address": false,
// "case.location.city": false,
// "case.location.stateAbbr": false,
// "case.location.zipCode": false,
