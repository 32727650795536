import { useMemo } from "react";
import {
  getGridDateOperators,
  GridColDef,
  GridFilterOperator,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";

import {
  usStateSelectOptions,
  CaseStatus,
  caseStatusNames,
  Contact
} from "auditaware-types";

// This is necessary because the default singleSelect operators do not include an isEmpty operator
// and we need it to filter out empty values when linking from the reports page
// specifically for caseType and status
const isEmptyOperator: GridFilterOperator = {
  label: "is empty",
  value: "isEmpty",
  getApplyFilterFn: (filterItem) => {
    return (value: string | null | undefined) => {
      return value === null || value === undefined || value === "";
    };
  },
};

import { SubjectAvatar } from "../components/shared/AllListGrid/TypeAvatar";
import GridItem from "../components/shared/AllListGrid/GridItem";
import { PrettyStatus } from "../components/shared/PrettyStatus";
import { options as caseTypes } from "../components/shared/Form/CaseTypeSelect";
import { options as subjectTypes } from "../components/shared/Form/SubjectTypeSelect";
import {
  nestedValueGetter,
  renderCell,
  renderDateCell,
  renderLinkCell,
  renderHeader,
  renderNoHeader,
  renderQuickEditCell,
} from "../components/shared/DataGridHelpers";

import useAssignees from "./useAssignees";

type CaseStatusOptions = {
  label: typeof caseStatusNames[keyof typeof caseStatusNames];
  value: `${CaseStatus}`;
}

const statusOptions: CaseStatusOptions[] = Object.values(CaseStatus).map((value) => ({
  label: caseStatusNames[value as CaseStatus],
  value: value as CaseStatus,
}));

const getDisplayName
  = ({ primaryName, secondaryName }: Contact = {} as Contact): string => [
    primaryName, secondaryName
  ].join(" ").trim();

const useOverviewColumns = () => {
  const assignees = useAssignees();

  const assigneeOptions = useMemo(
    () => [
      { value: "unassigned", label: "Unassigned" },
      ...assignees.map(({ id: value, displayName: label }) => ({ value, label })),
    ],
    [assignees]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "subject.subjectType",
        type: "singleSelect",
        valueOptions: subjectTypes,
        valueGetter: nestedValueGetter,
        headerName: "Subject Type",
        width: 24,
        renderHeader: renderNoHeader,
        renderCell: (params) => {
          const { subjectType } = params.row.subject;

          return <SubjectAvatar subjectType={subjectType} />;
        },
        sortable: false,
        display: "flex",
      },
      {
        field: "case.caseType",
        type: "singleSelect",
        filterOperators: [...getGridSingleSelectOperators(), isEmptyOperator],
        valueOptions: caseTypes,
        valueGetter: nestedValueGetter,
        headerName: "Case Type",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.subjectData.score",
        valueGetter: nestedValueGetter,
        type: "number",
        headerName: "Score",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.location.parcelId",
        headerName: "Parcel ID",
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.externalId",
        valueGetter: nestedValueGetter,
        headerName: "Unique ID",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.contact.primaryName",
        valueGetter: nestedValueGetter,
        headerName: "Name",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.contact.naics",
        valueGetter: nestedValueGetter,
        headerName: "NAICS",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.location.countyName",
        valueGetter: nestedValueGetter,
        headerName: "Subject County",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.location.jurisdictionName",
        valueGetter: nestedValueGetter,
        headerName: "Jurisdiction",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.location.address",
        valueGetter: nestedValueGetter,
        headerName: "Address",
        renderHeader,
        flex: 1,
        renderCell,
        display: "flex",
      },
      {
        field: "subject.location.city",
        valueGetter: nestedValueGetter,
        headerName: "City",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.location.stateAbbr",
        valueGetter: nestedValueGetter,
        type: "singleSelect",
        valueOptions: usStateSelectOptions,
        getOptionLabel: (value: any) => value.value,
        headerName: "State",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.location.zipCode",
        valueGetter: nestedValueGetter,
        headerName: "Zip",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.contact.email",
        valueGetter: nestedValueGetter,
        headerName: "Email",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.contact.website",
        valueGetter: nestedValueGetter,
        headerName: "Website",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "subject.contact.phoneNumber",
        valueGetter: nestedValueGetter,
        headerName: "Phone #",
        renderHeader,
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "status",
        headerName: "Status",
        filterOperators: [...getGridSingleSelectOperators(), isEmptyOperator],
        renderHeader,
        flex: 1,
        display: "flex",
        type: "singleSelect",
        valueOptions: statusOptions,
        valueGetter: (value, row) => row.case?.status,
        renderCell: ({ row: { case: c } }) =>
          c && (
            <GridItem title={c.status} variant="body2" sx={{ fontWeight: 900 }}>
              <PrettyStatus status={c.status} />
            </GridItem>
          ),
      },
      {
        field: "case.cycle",
        headerName: "Cycle",
        valueGetter: nestedValueGetter,
        renderHeader,
        flex: 1,
        display: "flex",
        renderCell,
      },
      {
        field: "case.location.parcelId",
        headerName: "Case Parcel ID",
        renderHeader,
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "case.location.countyName",
        headerName: "Case County",
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
      },
      {
        field: "case.location.jurisdictionName",
        headerName: "Case Jurisdiction",
        flex: 1,
        display: "flex",
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
      },
      {
        type: "singleSelect",
        field: "case.assignee.displayName",
        valueOptions: assigneeOptions,
        headerName: "Assignee",
        renderHeader,
        renderCell: ({ row: { case: c } }) => (
          <Typography>{c?.assignee?.displayName}</Typography>
        ),
        valueGetter: (value, row) => row.case?.assignee?.id || "unassigned",
        flex: 1,
        display: "flex",
      },
      {
        field: "case.scheduledOn",
        headerName: "Scheduled Date",
        renderHeader,
        renderCell: renderDateCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "case.confirmationSentOn",
        headerName: "Confirmation Sent On",
        renderHeader,
        renderCell: renderDateCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "case.documentsReceivedOn",
        headerName: "Docs Received On",
        renderHeader,
        renderCell: renderDateCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "case.primaryContact.displayName",
        headerName: "Primary Name",
        renderHeader,
        renderCell: (params) => {
          const { primaryContact } = params.row.case || {};
          const displayName = getDisplayName(primaryContact) || " ";
          return <Typography>{displayName}</Typography>;
        },
        valueGetter: (value, row) => getDisplayName(row.case?.primaryContact),
        flex: 1,
        display: "flex",
      },
      {
        field: "case.primaryContact.phoneNumber",
        headerName: "Primary Phone",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "case.primaryContact.email",
        headerName: "Primary Email",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "case.location.address",
        headerName: "Case Address",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "case.location.city",
        headerName: "Case City",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "case.location.stateAbbr",
        headerName: "Case State",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "case.location.zipCode",
        headerName: "Case Zip",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
        display: "flex",
      },
      {
        field: "mostRecent",
        headerName: "Most Recent Case",
        renderHeader,
        type: "singleSelect",
        valueOptions: ["Yes", "No"],
        valueGetter: (value, row) => row.mostRecent ? "Yes" : "No",
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "active",
        headerName: "Case Active",
        renderHeader,
        type: "singleSelect",
        valueOptions: ["Yes", "No"],
        valueGetter: (value, row) => row.active ? "Yes" : "No",
        renderCell,
        flex: 1,
        display: "flex",
      },
      {
        field: "quickEdit",
        headerName: "Quick Edit",
        renderHeader: renderNoHeader,
        sortable: false,
        disableColumnMenu: true,
        width: 40,
        renderCell: renderQuickEditCell,
        valueGetter: (value, row) => row.setQuickEditCaseId,
        display: "flex",
      },
      {
        field: "details",
        headerName: "Details",
        renderHeader: renderNoHeader,
        sortable: false,
        disableColumnMenu: true,
        width: 40,
        renderCell: renderLinkCell,
        valueGetter: (value, row) =>
          ["/details", row.subject.id, row.case?.id].filter((x) => x).join("/"),
        display: "flex",
      },
    ],
    [assigneeOptions]
  );

  return columns;
};

export default useOverviewColumns;
// {
//   field: "subject.externalId",
//   headerName: "Unique ID",
//   renderHeader,
//   flex: 1,
//   valueGetter: nestedValueGetter,
//   renderCell,
// },
// {
//   field: "subject.contact.primaryName",
//   headerName: "Subject",
//   flex: 1,
//   renderHeader,
//   valueGetter: nestedValueGetter,
//   renderCell,
// },
