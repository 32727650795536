import React, { FC } from "react";
import { Form, useFormikContext } from "formik";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { Case } from "auditaware-types";
import GridTextField from "../GridTextField";
import { CaseResultTypeSelect } from "../SelectCaseResultType";
import GridDateField from "../GridDateField";
import { UserSelect } from "../Form/AssigneeSelect";

type ResultsFormProps = {
    closeDrawer: () => void;
};

const ResultsForm: FC<ResultsFormProps> = ({ closeDrawer }) => {
  const { isSubmitting } = useFormikContext<Case>();

  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid size={12}>
            <CaseResultTypeSelect name="caseResultTypeId" label="Result" />
          </Grid>
          <GridDateField
            name="resultsLetterSentOn"
            label="Results Letter Sent On"
            xs={12}
          />
          <GridTextField name="resultSummary" label="Result Summary" xs={12} />
          <GridDateField name="reviewCompletedOn" label="Review Completed On" xs={12} />
          <Grid size={12}>
            <UserSelect name="reviewedByUid" label="Reviewed By" />
          </Grid>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              width: "800px",
              borderTop: "1px solid #e0e0e0",
              display: "flex",
              justifyContent: "space-between",
              padding: ".5rem",
              zIndex: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="success"
              disabled={isSubmitting}
              fullWidth={true}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              disabled={isSubmitting}
              fullWidth={true}
              sx={{ ml: 2 }}
              onClick={closeDrawer}
              type="reset"
            >
              Cancel
            </Button>
          </Box>
        </Grid>
      </Form>
    </>
  );
};

export default ResultsForm;
