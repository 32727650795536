import { FC } from "react";
import { useParams } from "react-router-dom";

import Spinner from "../../shared/LogoSpinner/Spinner";
import SubjectDetails from "../../shared/Details/SubjectDetails";
import NewCase from "../../shared/Case/NewCase";
import CaseDetails from "../../shared/Details/CaseDetails";
import { useSubject } from "../../../hooks/subjectHooks";

const Details: FC = () => {
  const { subjectId = "", caseId = "" } = useParams();
  const { loading, error, data } = useSubject(subjectId);

  const subject = data?.organization?.subject;
  const cases = subject?.cases;
  const selectedCase = cases?.find((c) => c.id === caseId);

  if (error) {
    console.error(error);
    return <Spinner />;
  }

  if (loading || !subject) return <Spinner />;

  return (
    <>
      <SubjectDetails subject={subject} />
      <CaseDetails
        firstAuditYear={subject.firstAuditYear}
        case={selectedCase}
        cases={cases}
      />
      <NewCase />
    </>
  );
};

export default Details;
