import { FC } from "react";
import { Field, FieldProps, Form, useFormikContext } from "formik";
import { Box, Button, Chip, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { CheckboxField } from "auditaware-ui";
import { Case, Subject } from "auditaware-types";

import CaseFormFields from "../Form/CaseFormFields";
import UsStateSelect from "../../shared/Form/UsStateSelect";
import GridTextField from "../../shared/GridTextField";

type CaseFormProps = {
  closeDrawer: () => void;
  subject: Subject;
  isEditMode: boolean;
};

const SectionDivider = ({ title }: { title: string }) => (
  <Grid size={12} my={1}>
    <Divider>
      <Chip label={title} />
    </Divider>
  </Grid>
);

const CaseForm: FC<CaseFormProps> = ({ closeDrawer, subject, isEditMode }) => {
  const { isSubmitting } = useFormikContext<Case>();

  return (
    <Form>
      <Grid container spacing={2}>
        <CaseFormFields isEditMode={isEditMode} />

        <SectionDivider title="Location" />
        <Field as={null}>
          {({ form: { values } }: FieldProps<Case>) => {
            const disabled = values.locationId === subject.locationId;
            const isNew = !values.id;

            return (
              <>
                <Grid size={{ xs: 12 }}>
                  <CheckboxField
                    name="locationId"
                    label="Location Same as Subject"
                    onValue={subject.locationId}
                    undefinedOff={isNew || disabled}
                  />
                </Grid>
                <GridTextField name="location.address" xs={12} disabled={disabled} />
                <GridTextField name="location.city" xs={12} disabled={disabled} />
                <Grid size={{ xs: 12, md: 6, lg: 6 }}>
                  <UsStateSelect disabled={disabled} name="location.stateAbbr" />
                </Grid>
                <GridTextField
                  name="location.zipCode"
                  disabled={disabled}
                  xs={12}
                  md={6}
                  lg={6}
                />
                <GridTextField
                  label="Parcel ID"
                  name="location.parcelId"
                  disabled={disabled}
                  xs={12}
                />
              </>
            );
          }}
        </Field>
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "800px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={isSubmitting}
          fullWidth={true}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={closeDrawer}
          type="reset"
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

export default CaseForm;
