import React, { FC } from "react";
import Grid from "@mui/material/Grid2";

import { Case } from "auditaware-types";

import CaseOverview from "../Case/CaseOverview";
import CaseNotes from "../Case/CaseNotes";
import CaseAttachments from "../Case/CaseAttachments";
import CasesTable from "./CasesTable";
import CaseResults from "../Case/CaseResults";
import CaseFees from "../Case/CaseFees";
import CaseValuations from "../Case/CaseValuations";

type CaseDetailProps = {
  firstAuditYear?: number | null;
  case?: Case;
  cases?: Case[];
};

const CaseDetails: FC<CaseDetailProps> = ({
  firstAuditYear,
  case: caseData,
  cases = [],
}) => {
  if (!caseData) {
    return null;
  }

  const { id: caseId } = caseData;

  return (
    <>
      <CaseOverview caseData={caseData} />
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
          <CaseNotes caseId={caseId} notes={caseData.notes} />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 6 }}>
          <CaseAttachments caseId={caseId} attachments={caseData.attachments} />
        </Grid>
      </Grid>
      <CasesTable cases={cases} />
      <Grid container spacing={2} marginTop={1}>
        <Grid size={{ xs: 12, md: 8, lg: 8 }}>
          <CaseResults caseData={caseData} />
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <CaseFees caseData={caseData} />
        </Grid>
        <Grid size={12}>
          <CaseValuations
            valuations={caseData.esaValuations}
            caseId={caseId}
            caseType={caseData.caseType}
            caseCreatedAt={caseData.createdAt}
            caseFirstAuditYear={firstAuditYear}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CaseDetails;
