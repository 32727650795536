import React, { FC } from "react";
import { Form, useFormikContext } from "formik";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { Case } from "auditaware-types";
import GridTextField from "../GridTextField";

type FeesFormProps = {
    closeDrawer: () => void;
};

const FeesForm: FC<FeesFormProps> = ({ closeDrawer }) => {
  const { isSubmitting } = useFormikContext<Case>();

  return (
    <>
      <Form>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <GridTextField
              name="feeAmount"
              label="Fee Amount"
              type="number"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <GridTextField
              name="feeSize"
              label="Fee Size"
              type="text"
              xs={12}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <GridTextField
              name="feeInvoicedOn"
              label="Fee Invoiced On"
              type="date"
              xs={12}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "800px",
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "space-between",
            padding: ".5rem",
            zIndex: 1,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="success"
            disabled={isSubmitting}
            fullWidth={true}
          >
              Save
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            disabled={isSubmitting}
            fullWidth={true}
            sx={{ ml: 2 }}
            onClick={closeDrawer}
            type="reset"
          >
              Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default FeesForm;
